import { once } from 'lodash';
import { SEGMENT_WRITE_KEY } from 'utils/env/client';

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/

export const categories = {
  TICKET_INFO: 'See Tickets Info',
  HOURS_INFO: 'See Hours Info',
  ERC_INFO: 'See ERC general Info',
  GROUPS_INFO: 'See Group Sales Info',
  PURCHASE: 'Make a purchase',
  VENUE_INFO: 'See Venue Info',
  SLIDE_INFO: 'See Slide Info',
};

const TIK_TOK_EVENTS = [
  { key: 'Menu sublink pressed', value: 'ViewContent' },
  { key: 'Product Added', value: 'AddToCart' },
  { key: 'Checkout Started', value: 'InitiateCheckout' },
  { key: 'Order Completed', value: 'PlaceAnOrder' },
  { key: 'Footer - Viewed Contact Us', value: 'Contact' },
];

const PINTEREST_EVENTS = [
  { key: 'Product Added', value: 'addtocart' },
  { key: 'Order Completed', value: 'checkout' },
  { key: 'View hours opened', value: 'viewcategory' },
  { key: 'Directory visted', value: 'viewcategory' },
  { key: 'Menu sublink pressed', value: 'pagevisit' },
];

const urlCategories = [
  {
    regex: /\/ticket/,
    category: categories.TICKET_INFO,
  },
  {
    regex: /\/group-sales/,
    category: categories.GROUPS_INFO,
  },
  {
    regex: /birthday/,
    category: categories.GROUPS_INFO,
  },
];

let resolveIdentify;
let identified;
let traits = {};

function init() {
  identified = new Promise(function (resolve) {
    resolveIdentify = resolve;
  });
}

function call(name, ...args) {
  const { analytics } = window;
  const fn = analytics && analytics[name];
  if (fn) {
    fn.apply(analytics, args);
  } else {
    //console.info.apply(console, [`Analytics: ${name} event`, ...args]);
  }
}

function getTikTokParameters(event, properties) {
  const parameters = {
    content_type: event,
  };
  if (event === 'Menu sublink pressed')
    parameters.content_name = properties?.label;
  if (event === 'Product Added') {
    parameters.content_type = 'product';
    parameters.content_name = properties?.ecommerce?.items[0]?.item_id;
    parameters.price = properties?.ecommerce?.value;
    parameters.quantity = properties?.ecommerce?.items[0]?.quantity;
  }
  if (event === 'Checkout Started' || event === 'Order Completed') {
    parameters.value =
      properties?.ecommerce?.value || properties?.ecommerce?.total;
    parameters.currency = properties?.ecommerce?.currency;
  }

  return parameters;
}

function getPinterestParameters(event, properties) {
  const parameters = {};
  if (event === 'Product Added') {
    parameters.value = properties?.ecommerce?.value;
    parameters.quantity = properties?.ecommerce?.items[0]?.quantity;
    parameters.currency = properties?.ecommerce?.currency || 'USD';
  }

  if (event === 'Order Completed') {
    parameters.value = properties?.ecommerce?.value || properties?.total;
    parameters.currency = properties?.ecommerce?.currency;
    parameters.event_id = properties?.ecommerce?.order_id || '';
    parameters.order_quantity =
      properties?.ecommerce?.items?.reduce(
        (acc, product) => (acc += product.quantity),
        0
      ) || 0;
    parameters.product_ids =
      properties?.ecommerce?.items?.map(({ sku }) => sku) || [];
  }
  return parameters;
}

// eslint-disable-next-line no-unused-vars
function trackTikTok(event, properties) {
  if (!window.ttq) return;
  const tikTokEvent = TIK_TOK_EVENTS.find((e) => e.key === event);
  if (!tikTokEvent) return;
  const parameters = getTikTokParameters(event, properties);
  window.ttq.track(tikTokEvent.value, parameters);
}

function trackPinterest(event, properties) {
  if (!window.pintrk) return;
  const pinterestEvent = PINTEREST_EVENTS.find((e) => e.key === event);
  if (!pinterestEvent) return;
  const parameters = getPinterestParameters(event, properties);
  window.pintrk('track', pinterestEvent.value, parameters);
}

async function hashString(str) {
  //Hash a string using SHA-256 and return the result as a hex string
  if (window.crypto) {
    try {
      const hashBuffer = await window.crypto.subtle.digest(
        'SHA-256',
        new TextEncoder().encode(str.trim())
      );
      const hashString = Array.from(new Uint8Array(hashBuffer))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
      return hashString;
    } catch (e) {
      console.info('Error hassing user email', e);
    }
  }
  return '';
}

export function getCategoryByCta(url) {
  const urlCategory = urlCategories.find((i) => i.regex.test(url));

  return urlCategory ? urlCategory.category : null;
}

export async function trackPage(name, properties = {}) {
  await identified;
  call('page', name, Object.assign({}, traits, properties));
}

export async function track(event, properties = {}) {
  await identified;
  call('track', event, Object.assign({}, traits, properties));
  //Tracking Pinterest
  trackPinterest(event, properties);
}

// [Event Type] [Element Type] - [Section] - [Component Name]
export async function standardizedTrack(
  eventType,
  elementType,
  section,
  name,
  category,
  label = window.location.pathname
) {
  if (
    !eventType ||
    !elementType ||
    !section ||
    !name ||
    !category ||
    !Object.keys(categories).some((key) => categories[key] === category)
  ) {
    throw new Error('Invalid tracking');
  }

  const action = `${eventType} ${elementType} - ${section} - ${name}`;

  return track(action, { label, category });
}

export function identify(user = {}) {
  if (user.id) {
    call('identify', user.id, user);
  } else {
    call('identify', user);
  }
  resolveIdentify();
}

export const loadAnalytics = once(function loadAnalytics() {
  call('load', SEGMENT_WRITE_KEY);
});

export const trackBingPixel = (eventName, properties) => {
  window.uetq = window.uetq || [];
  window.uetq.push('event', eventName, properties);
};

export const trackEpsilon = async (
  meStore,
  appSessionStore,
  promoId = 1,
  fid = 101,
  department = null,
  order = null
) => {
  window.dtm_config = {};
  try {
    if (appSessionStore.isLoggedIn()) await meStore.fetch();

    const { user } = meStore || {};
    const { bookingReference, totalAmount, currency } = order || {};

    if (user) {
      window.dtm_config.dtm_user_id = user.id;
      window.dtm_config.dtm_email_hash = await hashString(
        user.email.toLowerCase()
      );
    }

    if (department) window.dtm_config.dtmc_department = department;

    if (order) {
      const groupedTickets = order?.tickets?.reduce((acc, cur) => {
        const { venueName, totalAmountDiscount, quantity } = cur.ticketOption;
        if (!acc[venueName]) {
          acc[venueName] = {
            product_id: venueName,
            item_amount: 0,
            item_quantity: 0,
          };
        }
        acc[venueName].item_amount += totalAmountDiscount / 100;
        acc[venueName].item_quantity += quantity;
        return acc;
      }, {});
      window.dtm_config.dtm_items = Object.values(groupedTickets).map(
        (item) => ({
          ...item,
          item_amount: item.item_amount.toString(),
          item_quantity: item.item_quantity.toString(),
        })
      );
      window.dtm_config.dtmc_transaction_id = bookingReference || '';
      window.dtm_config.dtm_conv_val = totalAmount
        ? (totalAmount / 100).toString()
        : '';
      window.dtm_config.dtm_conv_curr = currency || '';
    }

    const script = document.createElement('script');
    const cachebust = Date.now();
    script.src = `https://login.dotomi.com/profile/visit/js/1_0?dtm_cid=82172&dtm_cmagic=83a5a9&dtm_fid=${fid}&dtm_promo_id=${promoId}&cachebuster=${cachebust}`;
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(script);
    script.remove();
  } catch (e) {
    console.warn('Epsilon: Error tracking', e);
    return;
  }
};

init();
